import { array } from 'prop-types';
import * as styles from './articles.module.scss';
import { Wrap } from '../sections-wrap';
import { ArticleCard } from '../article-card';

function Articles({ blogPosts }) {
  return (
    <div className={styles.articlesContainer}>
      <Wrap>
        <div className={styles.header}><h2 className="section-title">Articles</h2></div>
        <div className={styles.content}>
          {blogPosts?.map((blogPost) => (
            <ArticleCard
              title={blogPost.title}
              uri={blogPost.uri}
              date={
                {
                  date: blogPost.date,
                  dateModified: blogPost.schemaMarkup.eaPostSchemaUploadDate,
                  videoName: blogPost.schemaMarkup.eaPostSchemaVideoName,
                }
              }
              image={blogPost.featuredImage?.node}
              content={blogPost.excerpt}
            />
          ))}
        </div>
      </Wrap>
    </div>

  );
}

Articles.propTypes = {
  blogPosts: array,
};

Articles.defaultProps = {
  blogPosts: [],
};

export { Articles };
