import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Step } from '../components/step-section';
import { Section } from '../components/common/practice-child-step/section';
import { LegalJourney } from '../components/legal-journey';
import { Articles } from '../components/legal-journey-articles';
import * as styles from './templates.module.scss';
import { blogPostsFiltered, sortArticlesByDate } from '../helpers';

function PracticeChildStep1Template({ data }) {
  const stepData = data?.wpPage?.wpParent?.node;
  const currentUri = stepData.uri;
  let browseData = data?.wpPage?.wpParent?.node?.practiceToPostsConnection?.nodes.length < 100
    ? data?.wpPage?.wpParent?.node?.practiceToPostsConnection?.nodes : data?.relatedPosts.nodes;
  browseData = browseData?.sort((a, b) => (a.title?.localeCompare(b.title)));

  let blogPosts = blogPostsFiltered(browseData);
  blogPosts = sortArticlesByDate(blogPosts);

  const title = stepData?.template?.practiceChildConfiguration?.eaPracticeShortName?.toLowerCase();
  return (
    <main className={styles.stepPageStyles}>
      <Section data={stepData} />
      <Step step={1} text={`Learn about ${title}`} />
      <LegalJourney steps={4} currentStep={1} uri={currentUri} />
      <Articles blogPosts={blogPosts} />
    </main>
  );
}

export const query = graphql`
  query wpChildStepTemplate1($uri: String, $databaseId: Int) {
    relatedPosts: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {eq: $databaseId}}}}}) {
      nodes {
        title
        excerpt
        date
        schemaMarkup {
          eaPostSchemaUploadDate
          eaPostSchemaVideoName
        }
        uri
        categories {
          nodes {
            databaseId
          }
        }
        featuredImage {
          node {
            gatsbyImage(width:400)
          }
        }
      }
  }
    wpPage(uri: {eq: $uri}) {
      title
      uri
      wpParent {
        node {
          ... on WpPage {
            uri
            template {
              ... on WpTemplate_PracticeChild {
                practiceChildConfiguration {
                  eaPracticeShortName
                }
              }
            }
            wpParent {
              node {
                ... on WpPage {
                  title
                  uri
                }
              }
            }
            practiceToPostsConnection {
              nodes {
                title
                excerpt
                date
                schemaMarkup {
                  eaPostSchemaUploadDate
                  eaPostSchemaVideoName
                }
                uri
                categories {
                  nodes {
                    databaseId
                  }
                }
                featuredImage {
                  node {
                    gatsbyImage(width:400)
                  }
                }
              }
            }
          }
        }
      }
      ...SeoPage
    }
  }
`;

PracticeChildStep1Template.propTypes = {
  data: object,
};

PracticeChildStep1Template.defaultProps = {
  data: {},
};

export default PracticeChildStep1Template;
export { Head } from '../components/seo/seo';
